import React from 'react';
import { Link } from 'react-router-dom';

const TermsNavbar = () => {
  return (
    <nav style={{ backgroundColor: '#0F52BA', padding: '10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: 'white' }}>
        <Link to="/" style={{ color: 'white', textDecoration: 'none', fontSize: '20px', fontWeight: 'bold' }}>
          Checkaroundme
        </Link>
        <div>
          <Link to="/" style={{ color: 'white', marginRight: '15px', textDecoration: 'none' }}>Home</Link>
          <Link to="/terms-and-conditions" style={{ color: 'white', marginRight: '15px', textDecoration: 'none' }}>Terms</Link>
          <Link to="/privacy-policy" style={{ color: 'white', textDecoration: 'none' }}>Privacy</Link>
        </div>
      </div>
    </nav>
  );
};

export default TermsNavbar;
