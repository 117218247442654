import React from 'react';
import TermsNavbar from './TermsNavbar';

const PrivacyPolicy = () => {
  return (
    <div>
      <TermsNavbar />
      <div style={{ padding: '20px', fontFamily: 'Poppins', lineHeight: '1.6' }}>
      <h1>Privacy Policy</h1>
      <p>
        <strong>Introduction</strong><br />
        Welcome to Checkaroundme! This Privacy Policy outlines how we collect, use, share, and protect your personal data when you use our website, checkaroundme.com (the “Service”). We are committed to safeguarding your privacy and ensuring that your personal data is handled responsibly.
      </p>
      <h2>Data We Collect</h2>
      <p>
        <strong>Information You Provide:</strong> Identification data (name, phone number, email), contact data (address, phone), and profile data (preferences and interests). Verification data may include government-issued IDs for certain activities.
      </p>
      <p>
        <strong>Information Collected Automatically:</strong> Device and usage data, location data, advertising IDs, cookies, and similar technologies.
      </p>
      <h2>How We Use Your Personal Data</h2>
      <p>
        We use your personal data to provide and improve the Service, deliver personalized content and ads, communicate with you, and comply with legal obligations.
      </p>
      <h2>Sharing Your Personal Data</h2>
      <p>
        We may share your personal data with service providers, business partners, and regulatory authorities when required by law.
      </p>
      <h2>Security of Your Data</h2>
      <p>
        We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, disclosure, alteration, and destruction.
      </p>
      <h2>Cookies</h2>
      <p>
        We use cookies and similar tracking technologies to collect data about your activities on our Service. You can manage cookie preferences through your browser settings.
      </p>
      <h2>Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. If we make material changes, we will notify you through the Service or by other means.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at: <a href="mailto:support@checkaroundme.com">support@checkaroundme.com</a>.
      </p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
