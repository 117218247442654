import React from 'react'; 
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import HomePage from 'scenes/homePage';
import LoginPage from 'scenes/loginPage';
import ProfilePage from 'scenes/profilePage'; 
import NotFoundPage from 'scenes/NotFound';
import { useMemo } from 'react';
import TermsAndConditions from 'scenes/termsPolicy/TermsAndConditions';
import PrivacyPolicy from 'scenes/termsPolicy/PrivacyPolicy';
import { useSelector } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';
import { themeSettings } from './theme';
import state from './state';
import SuccessPage from 'scenes/paymentPage';
import CardDetailsPage from 'scenes/paymentPage';
import VerifyPage from 'scenes/verifyPage';

const App = () => {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth =  Boolean(useSelector((state) => state.token));
  console.log(isAuth)

  return (
    <div className="App">
      <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes> 
          <Route path='/' element={isAuth == false ? <LoginPage /> : <Navigate to="/home" />  } />
          <Route path='/verify' element={<VerifyPage />} />
          <Route path='/home' element={isAuth ? <HomePage /> : <Navigate to="/" />} />
          <Route path='/profile/:userId' element={isAuth ? <ProfilePage /> : <Navigate to="/" />} />
          <Route path='/payments' element={<SuccessPage />} />
          <Route path='/payments-with-card' element={<CardDetailsPage />} />
          <Route path="/not-found" component={NotFoundPage} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path='*' component={NotFoundPage} />
        </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
