import React from 'react';
import TermsNavbar from './TermsNavbar';



const TermsAndConditions = () => {
  return (
    <div>
      <TermsNavbar />
      <div style={{ padding: '20px', fontFamily: 'Poppins', lineHeight: '1.6' }}>
      <h1>Terms and Conditions</h1>
      <p>
        <strong>Introduction</strong><br />
        Welcome to Checkaroundme! These Terms and Conditions (the “Terms”) govern your use of the Checkaroundme platform, including our website and mobile applications (the “Service” or “Platform”). By accessing or using the Service, you agree to comply with these Terms. If you do not agree, please do not use the Service.
      </p>
      <h2>Platform Overview</h2>
      <p>
        Checkaroundme is a platform designed to connect service providers and clients. We facilitate interactions between individuals seeking professional services and those offering them, providing a space where users can find and book services in their area. We are not a service provider ourselves but a facilitator of these connections.
      </p>
      <h2>Account Registration</h2>
      <p>
        To access certain features of the Platform, you may need to create an account. You agree to provide accurate and complete information during the registration process and to update your information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. If you suspect any unauthorized use, please contact us immediately.
      </p>
      <h2>Use of the Platform</h2>
      <p>
        Service Listings: Service providers can list their services on the Platform, and clients can search for and book these services. All service listings must be accurate and comply with these Terms and any applicable laws.
      </p>
      <p>
        User Conduct: You agree to use the Platform in a lawful manner and not to engage in any activities that may harm the Platform, its users, or third parties.
      </p>
      <p>
        No Endorsement: While we strive to ensure the quality of services listed on our Platform, we do not endorse or guarantee the accuracy or reliability of any service provider or the services they offer.
      </p>
      <h2>Refund Policy</h2>
      <p>
        Refunds are available under specific conditions: <br />
        Subscription Fees: If you have paid a subscription fee for accessing premium features and are dissatisfied within the first 3 days, you may be eligible for a full refund.
      </p>
      <h2>Changes to Terms</h2>
      <p>
        We may update these Terms from time to time. We will notify you of any significant changes by posting the updated Terms on the Platform. Your continued use of the Service after any changes constitutes acceptance of the new Terms.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms or the Service, or if you need to file a dispute, please contact us at: <a href="mailto:support@checkaroundme.com">support@checkaroundme.com</a>.
      </p>
    </div>
    </div>
  );
};

export default TermsAndConditions;
